import './app.scss';
import Home from './home.js'

function App() {
  return (
      <div className="App">
        <Home/>
      </div>
  );
}

export default App;
