import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; 

import './navigation.scss';
import Popout from './offcanvas';

function Navigation(props){
  return(
    <Container className="navigation-container">
      <Container>
        <Row>
          <div className="navigation-logo">
            <h1>{props.company}</h1>
          </div>
          <div>
            <Row>
              <Popout navigationLinks={props.navigationLinks} />
            </Row>
          </div>
        </Row>
      </Container>
    </Container>
  )
}

export default Navigation;