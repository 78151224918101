import React, { useState } from "react";
import { Offcanvas, ListGroup } from 'react-bootstrap'; 
import { List } from 'react-bootstrap-icons';

function Popout(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigationLinksArray = [];
  props.navigationLinks.forEach(linkTarget => {
    const link = `#` + linkTarget.toLowerCase()
    navigationLinksArray.push(<ListGroup.Item style={{ padding: '3vh 25px'}}><a style={{textDecoration: 'none', cursor: 'pointer', color: '#1A181B' }} href={link} onClick={handleClose}>{linkTarget}</a></ListGroup.Item>)
  })

  return (
    <>
      <a onClick={handleShow}>
        <List style={{fontSize: '2em'}}/>
      </a>

      <Offcanvas show={show} onHide={handleClose} placement='end' backdrop='true' scroll='true'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            {navigationLinksArray}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Popout;