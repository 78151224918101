import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap'; 
import { ArrowRight } from 'react-bootstrap-icons';
import Typical from 'react-typical';
import './app.scss';
import { ReactComponent as Eating } from './assets/eating_orange.svg';
import { ReactComponent as Menu } from './assets/menu.svg';

// components
import Navigation from './components/navigation/navigation';

function Home() {
  return <div className="site-container">
    <Navigation id="start" company='Saint Marie' navigationLinks={['Start', 'Informatie', 'Openingsuren', 'Bestellen', 'Menu']}/>
    <section className="landing">
      <Container>
        <Row>
          <Col md={12}>
            <h1>
              <Typical className="typical"
                steps={['Pastabar', 2000, 'Cremerie', 2000]}
                loop={Infinity}
                wrapper='span'
              /> 
              <br/> 
              Saint Marie
            </h1>
            <p>
              Zin in een verfrissend ijsje, knapperige wafel of Italiaanse pasta? <br/>
              Kom eens langs in de Kiewistraat 131, 3500 Hasselt. <br/>
              Bestellen kan op: 011 22 11 36
            </p>
            <Row className="actions">
              <Col> <a href="#bestellen">Maak een bestelling <ArrowRight /></a><a href="#openingsuren" className="inverted">Onze openingsuren <ArrowRight /></a></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="informatie" id="informatie">
    <br/>
      <Container>
        <Row>
          <Col md={7} className='content'>
            <h4>INFORMATIE</h4>
            <h2>Waar kan je ons vinden?</h2><br/>
            <p>  
              Op de hoek in het centrum van de Banneux-wijk, vind je SaintMarie. <br/>
              Heerlijk ambachtelijk bereid ijs & verse pasta's, ook op bestelling! <br/>
              <br />
              Spring zeker eens binnen: Kiewistraat 131, 3500 Hasselt.
            </p>
            <br />
            <div id="studenten">
              <h4>
                Versterking gezocht!
              </h4>
              <p>
                Hou je van ijsjes en verdien je graag een centje bij? <br/>
                Wij zijn opzoek naar een toffe collega flex-job of jobstudent!<br /><br />
                Bel naar: 011 22 11 36
              </p>
              <br/>
              <a className="inverted" href="tel:011221136">Neem telefonisch contact op<ArrowRight /></a>  
            </div>
          </Col>
          <Col md={5}>
            <br /><br />
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5482.505756089057!2d5.346576650096174!3d50.94701596967946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c121688afd2b6b%3A0x639005d012681736!2sCremerie%2FPastabar%20-%20Saint%20Marie!5e0!3m2!1sen!2sbe!4v1649855404071!5m2!1sen!2sbe"></iframe>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="openingsuren" id="openingsuren">
    <br/>
      <Container>
        <h4>Openingsuren</h4>
        <Row>
          <Col md={7}>
            <h2>Wanneer kan ik afhalen?</h2>
            <br/>
            <h3><strong>Van 03.10.22 tot 21.10.22 zijn wij op verlof</strong></h3>
            <br/>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div>Maandag</div>
                <div>11.00u - 19.00u</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Dinsdag</div>
                <div>11.00u - 19.00u</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Woensdag</div>
                <span>Gesloten</span>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Donderdag</div>
                <span>Gesloten</span>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Vrijdag</div>
                <div>11.00u - 20.00u</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Weekend</div>
                <div>14.00u - 21.00u</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Feestdagen</div>
                <div>14.00u - 20.00u</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div></div>
                <div><h5><strong>Ijssalon blijft steeds open tot 21u</strong></h5></div>
              </ListGroup.Item>
            </ListGroup>
            <br/>
            <br />
          </Col>
          <Col md={5}>
            <Eating className="hero-image"/>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="order" id='bestellen'>
      <Container>
        <h4>bestellen</h4>
        <h2>Klaar om te bestellen?</h2>
        <br />
        <Row>   
          <Col md={9}>
            <p>
              Bestel telefonisch tijdens de openingsuren van de keuken op: <span>011 22 11 36 </span><br/>
              Nog geen keuze gemaakt? Bekijk onze menu met het pasta assortiment.
            </p>
          </Col>
          <Col className='order-action'><a href="#menu" className="">Bekijk onze menu <ArrowRight /></a></Col>
        </Row>
      </Container>
    </section>
    <section className="menu" id="menu">
      <Container>
        <Row>
          <Col md={7} className="menu-content">
            <h4>Menu</h4>
            <h2>Afhaal pasta menu</h2>
            <br />
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div>Spaghetti bolognese</div>
                <div>€9.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Lasagne</div>
                <div>€9.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Cannelloni (vlees)</div>
                <div>€9.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Ravioli (vlees)</div>
                <div>€10.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Rollade aubergines</div>
                <div>€9.00</div>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <div>Rigatoni amatriciana (spek)</div>
                <div>€9.00</div>
              </ListGroup.Item> */}
              <ListGroup.Item>
                <div>Rigatoni kip en wokgroenten</div>
                <div>€12.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Tagliatelle carbonara</div>
                <div>€9.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Tagliatelle champignons en spek</div>
                <div>€10.50</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Tagliatelle scampi</div>
                <div>€12.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Penne all' arrabiata</div>
                <div>€9.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Penne 4 formaggi (4 kazen)</div>
                <div>€11.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Penne pesto</div>
                <div>€10.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Torttelloni spinazie en ricotta</div>
                <div>€10.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>1 liter spaghetti saus</div>
                <div>€13.00</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>Ciabatta brood</div>
                <div>€2.50</div>
              </ListGroup.Item>
            </ListGroup>
            <br />
          </Col>
          <Col md={5}>
            <Menu className="menu-image"/>
          </Col>     
        </Row>
      </Container>
    </section>  
    <section className="footer">
      <Container>
        <footer>
          <span>©Saint Marie - Alle rechten voorbehouden</span>
          <span>Gemaakt door Maarten Bruyninx</span>
        </footer>  
      </Container>
    </section>
  </div>;
}

export default Home;